/** as we pretty much always need contentId & request context for any sort of form submission, why not a context to access this */

import { createContext, useContext } from 'react';

export type ContentContextProps = {
	contentId: string;
	requestToken: string;
	isLoggedIn: boolean;
};

export type ContentContext = {
	contentId: string;
	requestToken: string;
	isLoggedIn: boolean;
};

const Context = createContext<ContentContextProps>(undefined);
Context.displayName = 'ContentContext';

export function ContentContextProvider({
	children,
	contentId,
	requestToken,
	isLoggedIn
}: {
	contentId: string;
	requestToken: string;
	isLoggedIn: boolean;
	children: React.ReactNode;
}) {
	const contextValue = {
		contentId,
		requestToken,
		isLoggedIn
	};

	return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

export function useContentContext() {
	const context = useContext(Context);

	if (!context) throw new Error('Missing content context');

	return context as ContentContext;
}
